<template>
  <div class="card h-100">
    <div class="card-header d-flex align-items-center justify-content-between">
      <div class="card-title m-0">
        <h3 class="card-label">{{ $t('BREADCRUMBS.ILOVA') }}</h3>
      </div>

      <div class="card-button d-flex">
        <div class="card-toolbar">
          <router-link
            to="/standartappen/create"
            class="btn btn-primary font-weight-bolder text-right"
          >
            {{ $t('BREADCRUMBS.ILOVA') }} +</router-link
          >
        </div>
      </div>
    </div>

    <div class="card-body h-100">
      <v-data-table
        no-data-text="Malumot kiritilmagan"
        :headers="headers"
        hide-default-footer
        single-select
        @click:row="rowClick"
        item-key="id"
        :items="getData"
        class="row-pointer h-inherit no_v-data-table__wrapper_height"
      >
        <template v-slot:[`item.action`]="{ item }">
          <appenAction :index="item" />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import appenAction from './appenAction'
export default {
  components: {
    appenAction
  },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllAppen')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('BREADCRUMBS.ILOVA') }
    ])
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.CREATE_DATE'),
          value: 'createdfullDateTime'
        },
        {
          text: this.$t('TABLE_HEADER.UPDATE_DATE'),
          value: 'updatedfullDateTime'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getData() {
      return this.$store.state.requests.allAppen.results
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/appendetails/' + item.id + '/')
        row.select(false)
      }
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.no_v-data-table__wrapper_height > .v-data-table__wrapper {
  overflow: auto;
  height: 100%;
}
</style>

<style>
.no_v-data-table__wrapper_height > .v-data-table__wrapper {
  overflow: auto !important;
  height: 100%;
}
</style>
